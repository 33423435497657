import React from "react";
import UnderContructionPage from "../components/pages/UnderConstructionPage/UnderContructionPage";
import Workshops from "../components/pages/Workshops/Workshops";

export const pages = [
  {
    name: "Musikunterricht",
    primary: true,
    pages: [
      ["Instrumentalunterricht", <UnderContructionPage />],
      ["Gesangsunterricht", <UnderContructionPage />],
      ["Bandcoaching", <UnderContructionPage />],
      ["Workshops & Specials", <UnderContructionPage />],
      ["Schnupperclub", <UnderContructionPage />],
    ],
  },
  {
    name: "Kurse für Kinder",
    primary: true,
    home: <UnderContructionPage />,
    pages: [
      // ["Windelrocker", <UnderContructionPage />],
      // ["Zwergenrocker", <UnderContructionPage />],
      ["Sandkastenrocker", <UnderContructionPage />],
      ["Rhythmusrocker", <UnderContructionPage />],
      ["Instrumentenkarussell", <UnderContructionPage />],
      // ["Melodika und Ukulele für Kids", <UnderContructionPage />],
    ],
  },
  {
    name: "Musiktherapie",
    home: <UnderContructionPage />,
    primary: true,
    // pages: [
    //   ["Hintergrund", <UnderContructionPage />],
    //   ["Silke Knoll", <UnderContructionPage />]
    // ]
  },

  {
    name: "Infos und Gebühren",
    home: <UnderContructionPage />,
  },
  {
    name: "Über Uns",
    home: <UnderContructionPage />,
    pages: [
      ["Wer sind wir", <UnderContructionPage />],
      // ["Dein Weg mit uns", <UnderContructionPage />],
      ["Unser Team", <UnderContructionPage />],
      // ["Impressionen", <UnderContructionPage />],
      // ["Presse", <UnderContructionPage />]
    ],
  },

  // {
  //   name: "Events",
  //   home: <Workshops />,
  // },
  {
    name: "Kontakt",
    ext: "mailto:janina@musik-total.net",
  },
];
